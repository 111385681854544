// Core
import { AxiosError } from "axios";
import { HttpClient } from "./http.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataListProduct, IResponseProductCategory, RequestProduct, RequestProductCategory } from "@/models/interface/product.interface";
import { ResponseDetailProduct } from "@/models/interface/logistic.interface";

export class ProductServices extends HttpClient {
  public constructor() {
    super();
  }

  public listProduct(params: RequestQueryParamsModel): Promise<DataListProduct> {
    return this.get<DataListProduct>(Api.Product, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error)
      })
  }
  public detailProduct(id:string): Promise<ResponseDetailProduct> {
    return this.get<ResponseDetailProduct>(Api.Product + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error)
      })
  }
  public listProductCategory(params: RequestQueryParamsModel): Promise<IResponseProductCategory> {
    return this.get<IResponseProductCategory>(Api.ProductCategory, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error)
      })
  }
  public updateProductCategory(payload: RequestProductCategory , path:string): Promise<boolean> {
    return this.put<boolean, RequestProductCategory>(Api.ProductCategory+path , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }
  public createProductCategory(payload: RequestProductCategory): Promise<boolean> {
    return this.post<boolean, RequestProductCategory>(Api.ProductCategory , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }
  public createProduct(payload: RequestProduct): Promise<boolean> {
    return this.post<boolean, RequestProduct>(Api.Product , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }
  public updateProduct(payload: RequestProduct , id:string): Promise<boolean> {
    return this.put<boolean, RequestProduct>(Api.Product + `/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }
}

export const productService = new ProductServices();
