











































































































































































































import Vue from "vue";
import { productService } from "@/services/product.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataListProduct, ResponseProduct } from "@/models/interface/product.interface";
import { debounceProcess } from "@/helpers/debounce";
import { Messages } from "@/models/enums/messages.enum";
import { logisticServices } from "@/services/logistic.service";
import { DataResponseGetListWorkOrder, RequestResultWorkOrder, ResponseWorkOrderDetail, WorkOrderLines } from "@/models/interface/logistic.interface";
import moment from "moment";
import { masterServices } from "@/services/master.service";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { DataMasterUom } from "@/models/interface/settings.interface";
import { settingsServices } from "@/services/settings.service";
import { Mode } from "@/models/enums/global.enum";
export default Vue.extend({
  name: "ResultWorkOrder",
  data() {
    this.getListProducts = debounceProcess(this.getListProducts, 200);
    this.getListDocumentNumber = debounceProcess(this.getListDocumentNumber, 200);
    return {
      mode: '' as Mode,
      idForView: '' as string,
      form: this.$form.createForm(this, { name: 'resultworkorder' }),
      formFind: this.$form.createForm(this, { name: 'resultworkorderFind' }),
      totalElements: 0 as number,
      limit: 10 as number,
      page: 1 as number,
      view: false as boolean,
      dataDocumentNumber: [] as DataResponseGetListWorkOrder[],
      dataWorkOrderDetail: {} as ResponseWorkOrderDetail,
      selectedRowKeys: [] as number[],
      formRulesFind: {
        docNumber: {
          label: "Doc Number",
          name: "docNumber",
          placeholder: "Doc Number",
          decorator: [
            "docNumber",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
      },
      formRules: {
        woNumber: {
          label: "Work Order Number",
          name: "woNumber",
          placeholder: "Work Order Number",
          decorator: [
            "woNumber",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        woDate: {
          label: "Work Order Date",
          name: "woDate",
          placeholder: "Work Order Date",
          decorator: [
            "woDate",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        customerName: {
          label: "Customer Name",
          name: "customerName",
          placeholder: "Customer Name",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        customerAddress: {
          label: "Customer Address",
          name: "customerAddress",
          placeholder: "Customer Address",
          decorator: [
            "customerAddress",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        picName: {
          label: "PIC Name",
          name: "picName",
          placeholder: "PIC Name",
          decorator: [
            "picName",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        customerMaintenanceNumber: {
          label: "Customer Maintenance Number",
          name: "customerMaintenanceNumber",
          placeholder: "<auto generate>",
          decorator: [
            "customerMaintenanceNumber",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        mechanicName: {
          label: "Mechanic Name",
          name: "mechanicName",
          placeholder: "Mechanic Name",
          decorator: [
            "mechanicName",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        scheduleOrder: {
          label: "Schedule Order",
          name: "scheduleOrder",
          placeholder: "Schedule Order",
          decorator: [
            "scheduleOrder",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        description: {
          label: "Description",
          name: "description",
          placeholder: "Description",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        journalNumber: {
          label: "Journal Number",
          name: "journalNumber",
          placeholder: "Journal Number",
          decorator: [
            "journalNumber",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
      },
      loading: {
        table: false,
        loadingDocumentNumber: false,
        find: false,
        save: false
      },
      columnsTable: [
        {
          title: "No",
          dataIndex: "no",
          key: "no",
          width: 120,
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
          scopedSlots: { customRender: "unitCode" },
          width: 150,
        },
        {
          title: "Serial Number",
          dataIndex: "serialNumber",
          key: "serialNumber",
          scopedSlots: { customRender: "serialNumber" },
          width: 150,
        },
        {
          title: "Category",
          dataIndex: "assetCategory",
          key: "assetCategory",
          scopedSlots: { customRender: "assetCategory" },
          width: 150,
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "type" },
          width: 150,
        },
        {
          title: "Product Reserved",
          dataIndex: "productReservedId",
          key: "productReservedId",
          scopedSlots: { customRender: "productReservedId" },
          width: 200,
        },
        {
          title: "Qty Product Reserve",
          dataIndex: "qtyReserved",
          key: "qtyReserved",
          scopedSlots: { customRender: "qtyReserved" },
          width: 200,
        },
        {
          title: "UOM Reserve",
          dataIndex: "uomReservedId",
          key: "uomReservedId",
          scopedSlots: { customRender: "uomReservedId" },
          width: 200,
        },
        {
          title: "Product Used",
          dataIndex: "productUsedId",
          key: "productUsedId",
          scopedSlots: { customRender: "productUsedId" },
          width: 200,
          responsiveColSelect: [
            {
              name: "productUsedId",
              placeholder: "Product",
              value: "id",
              options: [] as ResponseProduct[],
              style: "width: 100%",
              disabled: false
            },
            {
              name: "status",
              placeholder: "Status",
              value: "id",
              options: [] as ResponseListMaster[],
              style: "width: 100%",
              disabled: false
            },
            {
              name: "reason",
              placeholder: "Reason",
              value: "id",
              options: [] as ResponseListMaster[],
              style: "width: 100%",
              disabled: false
            },
            {
              name: "uomUsedId",
              placeholder: "UOM Used",
              value: "id",
              options: [] as DataMasterUom[],
              style: "width: 100%",
              disabled: false
            },
            {
              name: "productReservedId",
              placeholder: "Product Reserve",
              value: "id",
              options: [] as ResponseProduct[],
              style: "width: 100%",
              disabled: true
            },
            {
              name: "uomReservedId",
              placeholder: "Uom Reserve",
              value: "id",
              options: [] as DataMasterUom[],
              style: "width: 100%",
              disabled: true
            },
          ],
          responsiveColInput: [
            {
              name: "qtyUsed",
              placeholder: "0",
              disabled: false,
              style: "width: 100%"
            },
            {
              name: "notes",
              placeholder: "Notes",
              disabled: false,
              style: "width: 100%"
            }
          ],
          responsiveColDateMonth: [
            {
              name: "completeDate",
              placeholder: "Select Date",
              style: "width: 100%;",
              disabled: ""
            },
          ],
        },
        {
          title: "Qty Product Used",
          dataIndex: "qtyUsed",
          key: "qtyUsed",
          scopedSlots: { customRender: "qtyUsed" },
          width: 200,
        },
        {
          title: "UOM Used",
          dataIndex: "uomUsedId",
          key: "uomUsedId",
          scopedSlots: { customRender: "uomUsedId" },
          width: 200,
        },
        {
          title: "Part Number",
          dataIndex: "partNumber",
          key: "partNumber",
          scopedSlots: { customRender: "partNumber" },
          width: 150,
        },
        {
          title: "Track As Asset",
          dataIndex: "trackAsAsset",
          key: "trackAsAsset",
          scopedSlots: { customRender: "trackAsAsset" },
          responsiveColCheckBox: ["trackAsAsset"],
          width: 180,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          scopedSlots: { customRender: "status" },
          width: 200,
        },
        {
          title: "Reason",
          dataIndex: "reason",
          key: "reason",
          scopedSlots: { customRender: "reason" },
          width: 200,
        },
        {
          title: "Date Time",
          dataIndex: "completeDate",
          key: "completeDate",
          scopedSlots: { customRender: "completeDate" },
          width: 200,
        },
        {
          title: "Notes",
          dataIndex: "notes",
          key: "notes",
          scopedSlots: { customRender: "notes" },
          width: 200,
        },
      ],
      dataList: [] as WorkOrderLines[]
    }
  },
  methods: {
    getListDetailResultWorkOrder () {
      let params: RequestQueryParamsModel = {}
      logisticServices
        .getResultWorkOrderDetail(params, this.idForView)
        .then((response) => {
          const findColumn: any = this.columnsTable.find(column => column.responsiveColSelect)?.responsiveColSelect;
          response.resultWorkOrderLineDTOS.forEach((dataForeach, index) => {
            dataForeach.no = (this.page - 1) * this.limit + index + 1;
            dataForeach.key = index;
            if (findColumn) {
                  findColumn[4].options.push({
                    barcode: '',
                    createdDate: '',
                    id: dataForeach.productReservedId,
                    modifiedDate: '',
                    name: dataForeach.productReservedName,
                    uomId: '',
                    value: '',
                    code: '',
                    description: '',
                    unit: '',
                  })
                  findColumn[5].options.push({
                    barcode: '',
                    createdDate: '',
                    id: dataForeach.uomReservedId,
                    modifiedDate: '',
                    name: dataForeach.uomReservedName,
                    uomId: '',
                    value: '',
                    code:'',
                    description:'',
                    unit:''
                  })
              }
          });
          this.form.setFieldsValue({
            woDate: moment(response.workOrderDate).format('DD-MMM-yyyy'),
            woNumber: response.workOrderId,
            customerName: response.customer,
            customerAddress: response.customerAddress,
            picName: response.picName,
            description: response.description,
            scheduleOrder: moment(response.scheduleOrder).format('DD-MMM-yyyy'),
            mechanicName: response.mechanic,
            customerMaintenanceNumber: response.documentNumber,
            journalNumber: response.journalNumber,
          })
          this.dataList = response.resultWorkOrderLineDTOS.map((dataMap) => {
            return { ...dataMap, disabledInput: true, disabledSelect: true, disabledCheckBox: true, disabledDateMonth: true }
          })
        })
    },
    addRow (): void {
      const newData = {
        productReservedId: '',
        serialNumber: '',
        assetCategory: '',
        createdDate: '',
        id: '',
        modifiedDate: '',
        notes: '',
        partNumber: '',
        productUsedId: '',
        productLocationId: '',
        productUomId: '',
        qtyAvailable: 0,
        qtyReserved: 0,
        assetId: '',
        unitCode: '',
        trackAsAsset: false,
        status: '',
        reason: '',
        type: '',
        qtyUsed: '',
        completeDate: '',
        locationId: '',
        key: this.dataList.length,
        no: this.dataList.length > 0 ? this.dataList[this.dataList.length - 1].no + 1 : 1,
      } as WorkOrderLines
      this.dataList = [...this.dataList, newData];
    },
    onSelectChange(value, key, col): void {
      this.dataList[key] = {...this.dataList[key],[col] :value};
      this.dataList = this.dataList.slice();
      this.$emit("data-update", this.dataList);
    },
    showConfirmation(): void {
      if(this.selectedRowKeys.length > 0) {
        this.$confirm({
          title: Messages.CONFIRM_DELETE_TITLE,
          content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
          onOk: () => {
            this.handleDeleteRow();
          },
          onCancel() {return;},
        });
      } else {
        this.$notification.error({
          message: 'Error',
          description: 'Select at least one row to delete',
        });
      }
    },
    handleDeleteRow(): void {
      const { dataList } = this;
      this.dataList = dataList.filter((data) => {
        return !this.selectedRowKeys.includes(data.key)
      });
      this.dataList.forEach((dataObject, index) => (dataObject.key = (this.page - 1) * this.limit + index + 1));
      this.dataList = this.dataList.slice();
      this.selectedRowKeys = [];
      this.$emit("data-update", this.dataList);
    },
    getCheckboxProps: record => ({
      props: {
        disabled: record.productReservedId || record.uomReservedId ? true : false, // Column configuration not to be checked
        name: record.productReservedId || record.uomReservedId,
      },
    }),
    onRowSelect(selectedRowKeys): void {
      this.selectedRowKeys = selectedRowKeys;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size
      this.page = 1;
      this.handleFind()
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page
      this.handleFind()
    },
    checkerUndefined (val): string {
      return val ? val : ''
    },
    handleSave () {
      this.form.validateFields((err,res) => {
        if(err) return;
        let payload:RequestResultWorkOrder = {
          customerId: this.dataWorkOrderDetail.customerId,
          description: this.checkerUndefined(res.description),
          mechanicId: this.dataWorkOrderDetail.mechanicId,
          picName: this.checkerUndefined(res.picName),
          resultWorkOrderLineDTOS: this.dataList.map((dataMap) => {
            return {
              ...dataMap,
              completeDate: dataMap.completeDate ? moment(dataMap.completeDate).set({hour: 0, minute: 0, second: 0}).utcOffset("+07").format() : '',
              locationId: dataMap.locationId ? dataMap.locationId : dataMap.productLocationId
            }
          }),
          // scheduledOrder: this.checkerUndefined(res.scheduleOrder),
          scheduledOrder: this.checkerUndefined(res.scheduleOrder) ? moment(res.scheduleOrder).set({hour: 0, minute: 0, second: 0}).utcOffset("+07").format() : '',
          workOrderId: this.dataWorkOrderDetail.id
        }
        this.loading.save = true
        setTimeout(() => {
          logisticServices
            .createResultWorkOrder(payload)
            .then(() => this.$message.success(Messages.CREATE_SUCCESS))
            .catch(() => this.$message.error(Messages.CREATE_FAIL))
            .finally(() => this.loading.save = false)
        }, 500);
      })
    },
    handleFind () {
      this.formFind.validateFields((err,res) => {
        if(err) return;
        let params = {
          page: this.page - 1,
          limit: this.limit
        } as RequestQueryParamsModel
        this.loading.find = true
        logisticServices
          .getListWorkOrderDetail(params, res.docNumber)
          .then(response => {
            this.form.setFieldsValue({
              woDate: moment(response.workOrderDate).format('DD-MMM-yyyy'),
              woNumber: response.documentNumber,
              customerName: response.customerName,
              customerAddress: response.customerAddress,
              picName: response.picName,
              description: response.description,
              scheduleOrder: moment(response.scheduleOrder).format('DD-MMM-yyyy'),
              mechanicName: response.mechanicName
            })
            const findColumn: any = this.columnsTable.find(column => column.responsiveColSelect)?.responsiveColSelect;
            
            response.workOrderLines.forEach((dataObject, index) => {dataObject.no = (this.page - 1) * this.limit + index + 1; dataObject.key = index});
            this.dataList = response.workOrderLines.map(dataMap => {
              if (findColumn) {
                findColumn[4].options.push({
                  barcode: '',
                  createdDate: '',
                  id: dataMap.productId,
                  modifiedDate: '',
                  name: dataMap.productName,
                  uomId: '',
                  value: '',
                  code: '',
                  description: '',
                  unit: '',
                })
                findColumn[5].options.push({
                  barcode: '',
                  createdDate: '',
                  id: dataMap.productUomId,
                  modifiedDate: '',
                  name: dataMap.productUomName,
                  uomId: '',
                  value: '',
                  code:'',
                  description:'',
                  unit:''
                })
            }
              return { ...dataMap, trackAsAsset: false, productReservedId: dataMap.productId, uomReservedId: dataMap.productUomId, qtyReserved: dataMap.qtyWorkOrder  }
            })
            this.dataWorkOrderDetail = response
          })
          .finally(() => this.loading.find = false)
      })
    },
    getListDocumentNumber (valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel
      if(valueSearch) params.search = `workOrder.documentNumber~*${valueSearch}*`
      this.loading.loadingDocumentNumber = true
      logisticServices
        .getListWorkOrder(params)
        .then(response => this.dataDocumentNumber = response.data)
        .finally(() => this.loading.loadingDocumentNumber = false)
    },
    getProducts(params: RequestQueryParamsModel): Promise<DataListProduct> {
      return productService.listProduct(params);
    },
    async getListProducts(): Promise<void> {
      try {
        const req: RequestQueryParamsModel = {
          limit: 10,
          page: 0
        };
        const res = await this.getProducts(req);
        const findColumn = this.columnsTable.find(column => column.responsiveColSelect)?.responsiveColSelect;
        if (findColumn) {
          findColumn[0].options = res.data;
        }
      } finally {
        console.log();
      }
    },
    getListOfBaseUnit(valueSearch): void {
      let params = {
        // baseUnit: true, // tidak dibutuhkan lagi
        page: 0,
        limit: 10
      } as RequestQueryParamsModel;
      if(valueSearch) params.search = `name~*${valueSearch}*`
      settingsServices
        .listOfBaseUnit(params)
        .then((res) => {
          res.data.forEach((dataObject, index) => {
            dataObject.name = dataObject.unit
            dataObject.key = index
          });
          const findColumn = this.columnsTable.find(column => column.responsiveColSelect)?.responsiveColSelect;
          if (findColumn) {
            findColumn[3].options = res.data;
          }
        })
    },
    getListStatusResultWo (valueSearch) {
      const params:RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        name: 'STATUS_RWO',
      }
      if(valueSearch) params.search = `value~*${valueSearch}*`
      masterServices.listMaster(params)
      .then((responseStatus) => {
        const findColumn = this.columnsTable.find(column => column.responsiveColSelect)?.responsiveColSelect;
        if (findColumn) {
          findColumn[1].options = responseStatus.map((dataMap) => {
            return { ...dataMap, name: dataMap.value, id: dataMap.value }
          });
        }
      })
    },
    getListReasonResultWo (valueSearch) {
      const params:RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        name: 'STATUS_RWO_UNIT',
      }
      if(valueSearch) params.search = `value~*${valueSearch}*`
      masterServices
      .listMaster(params)
      .then((responseReason) => {
        const findColumn = this.columnsTable.find(column => column.responsiveColSelect)?.responsiveColSelect;
        if (findColumn) {
          findColumn[2].options = responseReason.map((dataMap) => {
            return { ...dataMap, name: dataMap.value, id: dataMap.value }
          });
        }
      })
    },
    handleInput (value, key, column, columnName) {
      this.dataList[key] = {...this.dataList[key], [columnName]: value}
      this.dataList = this.dataList.slice()
    },
    handleCheckBoxTable(checked: boolean, key: any, nameColCheckbox: any, value: any): void {
      this.dataList[key].trackAsAsset = checked;
      this.dataList = this.dataList.slice()
    },
    handleSelectTable(value, key, col, recordOptions): void {
      console.log(value, key, col, recordOptions, 'test')
      this.dataList[key] = {...this.dataList[key], [col]: value}
      this.dataList = this.dataList.slice()
    },
    handleDateMonth (date, key, col, dateString) {
      this.dataList[key] = {...this.dataList[key], [col]: dateString}
      console.log (date, key, col, dateString)
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
  created() {
    this.mode = this.$route.meta.mode;
    this.getListProducts();
    this.getListDocumentNumber('')
    this.getListStatusResultWo('')
    this.getListReasonResultWo('')
    this.getListOfBaseUnit('')
  },
  mounted () {
    switch(this.mode) {
      case Mode.CREATE:
        break;
      case Mode.VIEW:
        this.view = true // disable or not
        this.idForView = this.$route.params.id;
        this.getListDetailResultWorkOrder()
        break
      default:
        break;
    }
  },
});
