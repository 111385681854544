var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Result Customer Maintenance" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form",
                                {
                                  attrs: {
                                    labelAlign: "left",
                                    layout: "inline",
                                    form: _vm.formFind
                                  }
                                },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.formRulesFind.docNumber.label,
                                        "label-col": {},
                                        "wrapper-col": {}
                                      }
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value:
                                                _vm.formRulesFind.docNumber
                                                  .decorator,
                                              expression:
                                                "formRulesFind.docNumber.decorator"
                                            }
                                          ],
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            name:
                                              _vm.formRulesFind.docNumber.name,
                                            placeholder:
                                              _vm.formRulesFind.docNumber
                                                .placeholder,
                                            showSearch: true,
                                            "option-filter-prop": "children",
                                            "filter-option": _vm.filterOption,
                                            loading:
                                              _vm.loading.loadingDocumentNumber,
                                            allowClear: true,
                                            disabled: _vm.view
                                          },
                                          on: {
                                            search: function(value) {
                                              return _vm.getListDocumentNumber(
                                                value
                                              )
                                            }
                                          }
                                        },
                                        _vm._l(_vm.dataDocumentNumber, function(
                                          data,
                                          index
                                        ) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: data.workOrderId }
                                            },
                                            [
                                              _c(
                                                "a-tooltip",
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "title" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "" +
                                                              data.workOrderNumber
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "" +
                                                          data.workOrderNumber
                                                      ) +
                                                      " "
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-item",
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.loading.find,
                                            disabled: _vm.view
                                          },
                                          on: { click: _vm.handleFind }
                                        },
                                        [_vm._v(" Find ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-tabs",
                                { attrs: { type: "card" } },
                                [
                                  _c(
                                    "a-tab-pane",
                                    {
                                      key: "result",
                                      staticClass: "bordertabs p-4",
                                      attrs: { tab: "Result" }
                                    },
                                    [
                                      _c(
                                        "a-row",
                                        { attrs: { gutter: [16, 16] } },
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "a-form",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      layout: "vertical",
                                                      form: _vm.form
                                                    }
                                                  },
                                                  "a-form",
                                                  _vm.formItemLayout,
                                                  false
                                                ),
                                                [
                                                  _c(
                                                    "a-row",
                                                    {
                                                      attrs: {
                                                        gutter: [16, 16]
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-col",
                                                        { attrs: { span: 12 } },
                                                        [
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.formRules
                                                                    .woNumber
                                                                    .label
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .woNumber
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.woNumber.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .woNumber
                                                                      .name,
                                                                  placeholder:
                                                                    _vm
                                                                      .formRules
                                                                      .woNumber
                                                                      .placeholder,
                                                                  disabled:
                                                                    _vm.view
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.formRules
                                                                    .woDate
                                                                    .label
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .woDate
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.woDate.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .woDate
                                                                      .name,
                                                                  placeholder:
                                                                    _vm
                                                                      .formRules
                                                                      .woDate
                                                                      .placeholder,
                                                                  disabled:
                                                                    _vm.view
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.formRules
                                                                    .customerName
                                                                    .label
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .customerName
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.customerName.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .customerName
                                                                      .name,
                                                                  placeholder:
                                                                    _vm
                                                                      .formRules
                                                                      .customerName
                                                                      .placeholder,
                                                                  disabled:
                                                                    _vm.view
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.formRules
                                                                    .customerAddress
                                                                    .label
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .customerAddress
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.customerAddress.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .customerAddress
                                                                      .name,
                                                                  placeholder:
                                                                    _vm
                                                                      .formRules
                                                                      .customerAddress
                                                                      .placeholder,
                                                                  disabled:
                                                                    _vm.view
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.formRules
                                                                    .picName
                                                                    .label
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .picName
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.picName.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .picName
                                                                      .name,
                                                                  placeholder:
                                                                    _vm
                                                                      .formRules
                                                                      .picName
                                                                      .placeholder,
                                                                  disabled:
                                                                    _vm.view
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.formRules
                                                                    .customerMaintenanceNumber
                                                                    .label
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .customerMaintenanceNumber
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.customerMaintenanceNumber.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .customerMaintenanceNumber
                                                                      .name,
                                                                  placeholder:
                                                                    _vm
                                                                      .formRules
                                                                      .customerMaintenanceNumber
                                                                      .placeholder,
                                                                  disabled: ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "a-col",
                                                        { attrs: { span: 12 } },
                                                        [
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.formRules
                                                                    .mechanicName
                                                                    .label
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .mechanicName
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.mechanicName.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .mechanicName
                                                                      .name,
                                                                  placeholder:
                                                                    _vm
                                                                      .formRules
                                                                      .mechanicName
                                                                      .placeholder,
                                                                  disabled:
                                                                    _vm.view
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.formRules
                                                                    .scheduleOrder
                                                                    .label
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .scheduleOrder
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.scheduleOrder.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .scheduleOrder
                                                                      .name,
                                                                  placeholder:
                                                                    _vm
                                                                      .formRules
                                                                      .scheduleOrder
                                                                      .placeholder,
                                                                  disabled:
                                                                    _vm.view
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.formRules
                                                                    .description
                                                                    .label
                                                              }
                                                            },
                                                            [
                                                              _c("a-textarea", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .description
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.description.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .description
                                                                      .name,
                                                                  placeholder:
                                                                    _vm
                                                                      .formRules
                                                                      .description
                                                                      .placeholder,
                                                                  disabled:
                                                                    _vm.view,
                                                                  "auto-size": {
                                                                    minRows: 3,
                                                                    maxRows: 5
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.formRules
                                                                    .journalNumber
                                                                    .label
                                                              }
                                                            },
                                                            [
                                                              _c("a-input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "decorator",
                                                                    rawName:
                                                                      "v-decorator",
                                                                    value:
                                                                      _vm
                                                                        .formRules
                                                                        .journalNumber
                                                                        .decorator,
                                                                    expression:
                                                                      "formRules.journalNumber.decorator"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .journalNumber
                                                                      .name,
                                                                  placeholder:
                                                                    _vm
                                                                      .formRules
                                                                      .journalNumber
                                                                      .placeholder,
                                                                  disabled: ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-row",
                                        { attrs: { gutter: [16, 16] } },
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "a-space",
                                                [
                                                  _c(
                                                    "a-button",
                                                    {
                                                      attrs: {
                                                        type: "danger",
                                                        disabled: _vm.view
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.showConfirmation
                                                      }
                                                    },
                                                    [_vm._v("Delete Row")]
                                                  ),
                                                  _c(
                                                    "a-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        disabled: _vm.view
                                                      },
                                                      on: { click: _vm.addRow }
                                                    },
                                                    [_vm._v("Add Row")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-space",
                                                {
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                },
                                                [
                                                  _c("TableCustom", {
                                                    attrs: {
                                                      idTable:
                                                        "tableresultworkorder",
                                                      dataSource: _vm.dataList,
                                                      columns: _vm.columnsTable,
                                                      paginationcustom: true,
                                                      defaultPagination: false,
                                                      loading:
                                                        _vm.loading.table,
                                                      scroll: { x: 2600 },
                                                      handleCheckBox:
                                                        _vm.handleCheckBoxTable,
                                                      handleInput:
                                                        _vm.handleInput,
                                                      handleSelect:
                                                        _vm.handleSelectTable,
                                                      handleDateMonth:
                                                        _vm.handleDateMonth,
                                                      onSelectChange:
                                                        _vm.onRowSelect,
                                                      getCheckboxProps:
                                                        _vm.getCheckboxProps,
                                                      selectedRowKeys:
                                                        _vm.selectedRowKeys
                                                    }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center"
                                                    },
                                                    [
                                                      _c("Pagination", {
                                                        attrs: {
                                                          idPagination:
                                                            "paginationresultworkorder",
                                                          total: 10,
                                                          pageSizeSet: 10
                                                        }
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "ml-auto"
                                                        },
                                                        [
                                                          !_vm.view
                                                            ? _c(
                                                                "a-space",
                                                                [
                                                                  _c(
                                                                    "a-button",
                                                                    {
                                                                      attrs: {
                                                                        type:
                                                                          "danger"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Cancel"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "a-button",
                                                                    {
                                                                      attrs: {
                                                                        type:
                                                                          "primary"
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.handleSave
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Save"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }